// extracted by mini-css-extract-plugin
export var active = "blog-overview-module--active--e93a3";
export var blogContainer = "blog-overview-module--blogContainer--2a6f4";
export var blogImg = "blog-overview-module--blogImg--fbeb4";
export var blogInfoBox = "blog-overview-module--blogInfoBox--ba5d5";
export var blogItem = "blog-overview-module--blogItem--3c8ff";
export var category = "blog-overview-module--category--d9b5b";
export var categoryBox = "blog-overview-module--categoryBox--da6ad";
export var dark = "blog-overview-module--dark--914db";
export var light = "blog-overview-module--light--3983c";
export var opacityIn = "blog-overview-module--opacity-in--1abc5";
export var seperator = "blog-overview-module--seperator--96a1e";
export var title = "blog-overview-module--title--18601";
export var titleBox = "blog-overview-module--titleBox--6d1ab";
export var titleLine = "blog-overview-module--titleLine--c4b3b";
export var titleSection = "blog-overview-module--titleSection--3e024";