import * as React from "react"
import { graphql, Link } from "gatsby"
import { useScroll } from "framer-motion"
import * as styles from "../styles/blog-overview.module.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import i18next from "i18next"
import { localizeString } from "../components/utils/localization"

function Ideas({ data }) {
  const { t, i18n } = useTranslation()

  let blogs = data.blogs.nodes

  //ScrollMotion Value
  const [isDark, setDark] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(false)
  const [isCategoryDesign, setIsCategoryDesign] = React.useState(false)
  const [isCategoryTechnology, setIsCategoryTechnology] = React.useState(false)
  const [isCategoryResearch, setIsCategoryResearch] = React.useState(false)
  const [isCategoryOther, setIsCategoryOther] = React.useState(false)
  const { scrollYProgress } = useScroll()
  const [sortedBlogs, setSortedBlogs] = React.useState(blogs)

  React.useEffect(
    () =>
      scrollYProgress.onChange(latest => {
        //Set Page to dark
        //Mobile
        if (window.innerWidth < 1200) {
          if (scrollYProgress.current > 0.125) {
            setDark(true)
          } else {
            setDark(false)
          }
        }
        //Desktop
        if (window.innerWidth >= 1200) {
          if (scrollYProgress.current > 0.075) {
            setDark(true)
          } else {
            setDark(false)
          }
        }
      }),
    []
  )

  //Init
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setisMobile(false)
      } else {
        setisMobile(true)
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  //Filter
  React.useEffect(() => {
    let sblogs = []

    blogs.forEach(b => {
      if (
        (isCategoryDesign &&
          b.childMdx.frontmatter.category.includes("design")) ||
        (isCategoryTechnology &&
          b.childMdx.frontmatter.category.includes("technology")) ||
        (isCategoryResearch &&
          b.childMdx.frontmatter.category.includes("research")) ||
        (isCategoryOther && b.childMdx.frontmatter.category.includes("other"))
      ) {
        sblogs.push(b)
      }
    })

    if (sblogs.length > 0) {
      setSortedBlogs(sblogs)
      return
    }

    setSortedBlogs(blogs)
  }, [
    isCategoryDesign,
    isCategoryOther,
    isCategoryResearch,
    isCategoryTechnology,
  ])

  return (
    <Layout
      type="ideas"
      isDark={isDark}
      isMobile={isMobile}
      key={i18n.language}
    >
      <section className={styles.titleSection}>
        <div className={styles.titleBox}>
          <div className={isDark ? "light" : "dark"}>
            <h1 className={styles.title}>
              <span>Blog</span>
            </h1>
          </div>
          <div
            className={
              isDark
                ? [styles.titleLine, styles.light, "light"].join(" ")
                : [styles.titleLine, styles.dark, "dark"].join(" ")
            }
          >
            <hr></hr>
          </div>
        </div>
        <div
          className={
            isDark
              ? [styles.categoryBox, styles.light, "light"].join(" ")
              : [styles.categoryBox, styles.dark, "dark"].join(" ")
          }
        >
          <span
            className={
              isCategoryDesign
                ? [styles.category, styles.active].join(" ")
                : styles.category
            }
            onClick={() => setIsCategoryDesign(!isCategoryDesign)}
          >
            Design
          </span>
          <span className={styles.seperator}>|</span>
          <span
            className={
              isCategoryTechnology
                ? [styles.category, styles.active].join(" ")
                : styles.category
            }
            onClick={() => setIsCategoryTechnology(!isCategoryTechnology)}
          >
            {t("technology")}
          </span>
          <span className={styles.seperator}>|</span>
          <span
            className={
              isCategoryResearch
                ? [styles.category, styles.active].join(" ")
                : styles.category
            }
            onClick={() => setIsCategoryResearch(!isCategoryResearch)}
          >
            {t("research")}
          </span>
          <span className={styles.seperator}>|</span>
          <span
            className={
              isCategoryOther
                ? [styles.category, styles.active].join(" ")
                : styles.category
            }
            onClick={() => setIsCategoryOther(!isCategoryOther)}
          >
            {t("other")}
          </span>
        </div>
      </section>
      <section
        className={
          isDark ? "portfolio-section light" : "portfolio-section dark"
        }
        id="ideas-section"
      >
        <div className={styles.blogWrapper}>
          <div className={styles.blogContainer}>
            {sortedBlogs.map(blog => (
              <div className={styles.blogItem} key={blog.childMdx.id}>
                <Link
                  to={localizeString("/blog/") + blog.childMdx.frontmatter.slug}
                >
                  <GatsbyImage
                    image={getImage(blog.childMdx.frontmatter.thumb)}
                    alt={blog.childMdx.frontmatter.thumbALT}
                    className={styles.blogImg}
                  />
                </Link>
                <div className={styles.blogInfoBox}>
                  <span className={styles.blogDate}>
                    {blog.childMdx.frontmatter.date}
                  </span>
                  <h4>{blog.childMdx.frontmatter.title}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query IdeasPageQuery($locale: String) {
    blogs: allFile(
      filter: {
        sourceInstanceName: { eq: "blogs" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
      sort: { fields: childrenMdx___frontmatter___order, order: DESC }
    ) {
      nodes {
        childMdx {
          id
          frontmatter {
            title
            category
            slug
            date
            thumbALT
            thumb {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default Ideas

export const Head = () => (
  <Seo
    title="Our Blog"
    titleDE="Unser Blog"
    description="The digital world offers unique possibilities, but also challenges. Here are some of our tips and tricks when it comes to design and technology."
    descriptionDE="Die digitale Welt bringt neue Möglichkeiten, aber auch Herausforderungen. Hier sind unsere Tipps und Tricks zum Thema Design und Technologie."
  />
)
